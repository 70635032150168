<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >

    <v-row justify="center">
      <v-col
        cols="12"
        md="10"
      >
        <v-row justify="center">
          <v-col
            cols="12"
            md="8"
          >
            <base-material-card
              color="primary"
            >
              <template v-slot:heading>
                <div class="display-2 font-weight-light">
                  Modifier le profil
                </div>
              </template>

              <v-form
                @submit.prevent="dialogUpdate = true;"
              >
                <v-container class="py-0">
                  <v-row>
                    <v-col
                      cols="12"
                    >
                      <v-text-field
                        outlined
                        v-model="user.username"
                        label="Adresse mail"
                        class="purple-input"
                        disabled
                      />
                    </v-col>

                    <v-col
                      cols="12"
                    >
                      <v-text-field
                        outlined
                        v-model="user.firstName"
                        label="Prénom"
                        class="purple-input"
                      />
                    </v-col>

                    <v-col
                      cols="12"
                    >
                      <v-text-field
                        outlined
                        v-model="user.lastName"
                        label="Nom"
                        class="purple-input"
                      />
                    </v-col>

                    <v-col
                      cols="12"
                      class="text-right"
                    >
                      <v-btn
                        color="primary"
                        class="mr-0"
                        type="submit"
                        :disabled="JSON.stringify(displayUser) === JSON.stringify(user)"
                      >
                        Mettre à jour
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </base-material-card>

            <base-material-card
              color="primary"
            >
              <template v-slot:heading>
                <div class="display-2 font-weight-light">
                  Modifier le mot de passe
                </div>
              </template>
              <v-form
                @submit.prevent="dialogPassword = true;"
              >
                <v-container class="py-0">
                  <v-row>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-row>
                        <v-col
                          cols="12"
                        >
                          <v-text-field
                            outlined
                            v-model="oldPassword"
                            label="Ancien mot de passe"
                            type="password"
                            persistent-hint
                            required
                          />
                        </v-col>
                        <v-col
                          cols="12"
                        >
                          <v-text-field
                            outlined
                            v-model="newPassword"
                            label="Nouveau mot de passe"
                            type="password"
                            required
                            @input="checkPasswordRules()"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                        >
                          <v-text-field
                            outlined
                            v-model="newPasswordConfirm"
                            label="Confirmer le nouveau mot de passe"
                            type="password"
                            required
                            @input="checkPasswordsSame()"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                      class="d-flex align-center"
                    >
                      <ul class="instructions-password">
                        <li :class="check8car">Le mot de passe doit contenir au moins 8 caractères</li>
                        <li :class="checkMin">Le mot de passe doit contenir au moins une minuscule</li>
                        <li :class="checkMaj">Le mot de passe doit contenir au moins une majuscule</li>
                        <li :class="checkNb">Le mot de passe doit contenir au moins un nombre</li>
                        <li :class="checkSpec">Le mot de passe doit contenir au moins un caractère spécial</li>
                        <li :class="checkSame">Les mots de passe doivent être identiques</li>
                      </ul>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      class="text-right"
                    >
                      <v-btn
                        color="primary"
                        class="mr-0"
                        type="submit"
                        :disabled="[oldPassword, newPassword, newPasswordConfirm].some((e) => e == '') || !checkPasswordRules() || !checkPasswordsSame()"
                      >
                        Mettre à jour
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </base-material-card>
          </v-col>

          <v-col
            cols="12"
            md="4"
          >
            <base-material-card
              color="primary"
            >
              <v-card-text class="text-center">

                <h4 class="display-2 font-weight-light mb-3 black--text">
                  {{ displayUser.firstName }} {{ displayUser.lastName }}
                </h4>

                <h6 class="display-1 mb-5 grey--text">
                  {{ displayUser.role }}
                </h6>

                <v-btn
                  color="primary"
                  rounded
                  class="mr-0"
                  @click="disconnect()"
                >
                  Déconnexion
                </v-btn>
              </v-card-text>
            </base-material-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <template>
      <v-dialog
        v-model="dialogUpdate"
        max-width="500px"
      >
        <v-card>
          <v-card-title
            class="headline"
          >
            Appliquer les modifications ?
          </v-card-title>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="red darken-1"
              @click="dialogUpdate = false"
            >
              Annuler
            </v-btn>
            <v-btn
              color="green darken-1"
              @click="update()"
            >
              Confirmer
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <template>
      <v-dialog
        v-model="dialogPassword"
        max-width="600px"
      >
        <v-card>
          <v-card-title
            class="headline"
          >
            Appliquer les modifications ?
          </v-card-title>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="red darken-1"
              @click="dialogPassword = false"
            >
              Annuler
            </v-btn>
            <v-btn
              color="green darken-1"
              @click="updatePassword()"
            >
              Confirmer
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </v-container>
</template>

<script>

  import { mapMutations } from 'vuex';

  export default {
    data() {
      return {
        dialogUpdate: false,
        dialogPassword: false,
        user: null,
        displayUser: null,
        oldPassword: '',
        newPassword: '',
        newPasswordConfirm: '',
        check8car: '',
        checkMin: '',
        checkMaj: '',
        checkNb: '',
        checkSpec: '',
        checkSame: '',
      }
    },

    created() {
      this.user = JSON.parse(localStorage.getItem('mcdm-user'));
      this.displayUser = Object.assign({}, this.user);
    },

    methods: {

      checkPasswordRules() {
        let passwordOk = false;

        // 8 caractères ou plus
        if (new RegExp('(?=.{8,})').test(this.newPassword)) {
          this.check8car = 'correct';
          passwordOk = true;
        } else {
          this.check8car = '';
          passwordOk = false;
        }

        // Minuscle
        if (new RegExp('(?=.*[a-z])').test(this.newPassword)) {
          this.checkMin = 'correct';
          passwordOk = true;
        } else {
          this.checkMin = '';
          passwordOk = false;
        }

        // Majuscle
        if (new RegExp('(?=.*[A-Z])').test(this.newPassword)) {
          this.checkMaj = 'correct';
          passwordOk = true;
        } else {
          this.checkMaj = '';
          passwordOk = false;
        }

        // Nombre
        if (new RegExp('(?=.*[0-9])').test(this.newPassword)) {
          this.checkNb = 'correct';
          passwordOk = true;
        } else {
          this.checkNb = '';
          passwordOk = false;
        }

        // Caractère spécial
        if (new RegExp('(?=.*[^A-Za-z0-9])').test(this.newPassword)) {
          this.checkSpec = 'correct';
          passwordOk = true;
        } else {
          this.checkSpec = '';
          passwordOk = false;
        }

        this.checkPasswordsSame();

        return passwordOk;
      },

      checkPasswordsSame() {
        let passwordOk = false;

        if (this.newPassword !== '' && this.newPassword === this.newPasswordConfirm) {
          this.checkSame = 'correct';
          passwordOk = true;
        } else {
          this.checkSame = '';
          passwordOk = false;
        }

        return passwordOk;
      },

      updatePassword() {
        this.axios.patch('/users/updatePassword', { oldPassword: this.oldPassword, newPassword: this.newPassword })
          .then((res) => {
            this.setSnack({ text: 'Le mot de passe a bien été mis à jour', type: 'success' });
            this.dialogPassword = false;
            this.oldPassword = '';
            this.newPassword = '';
            this.newPasswordConfirm = '';
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors de la modification du mot de passe', type: 'warn' });
          });
      },

      update() {
        this.axios.put('/users/' + this.user.id, this.user)
          .then((res) => {
            localStorage.setItem('mcdm-user', JSON.stringify(res.data));
            this.user = JSON.parse(localStorage.getItem('mcdm-user'));
            this.displayUser = Object.assign({}, this.user);
            this.dialogUpdate = false;
            this.setSnack({ text: 'Votre profil a bien été mis à jour', type: 'success' });
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors de la modification du profil', type: 'warn' });
          });
      },

      disconnect() {
        localStorage.removeItem('mcdm-user');
        localStorage.removeItem('mcdm-token');
        sessionStorage.removeItem('mcdm-token');
        this.axios.delete('/auth/refresh', { withCredentials: true });
        delete this.axios.defaults.headers.common.Authorization;
        this.$router.push({ name: 'Login' });
      },

      ...mapMutations({
        setSnack: 'SET_SNACK',
      }),
    },
  }
</script>

<style scoped>
  ul.instructions-password li {
    font-size: 0.85em;
    color: #b2b2b2;
    list-style-type: '\2715';
    margin: 6px 0;
    padding-left: 6px;
  }

  ul.instructions-password li.correct {
    list-style-type: '\2713';
    color: #4caf50;
  }
</style>
